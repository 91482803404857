// @flow
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import find from 'lodash/find';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import forOwn from 'lodash/forOwn';
import last from 'lodash/last';
import head from 'lodash/head';

import validators from '@cloudbusiness/common-react-components/lib/utils/validators';

// eslint-disable-next-line import/no-cycle
import applicationStore from '../applicationStore';
import {dispatchingIfErrors} from '../actions/commonActions/commonAppActions';

// eslint-disable-next-line import/no-cycle
import {
    generateNewMatrix,
    getCenterElement,
    hasCellEmptyNeighbor,
    increaseMatrixSize, matrixUpdateFunctionNames,
    updateMatrixFromRecursion
} from './flowMatrixUtils';
import errorsMessagesConstants, {
    PROVIDER_ACTION_ERROR
} from '../constants/flowValidationErrorMessages/errorsMessagesConstants';

import {
    ACTION_RECIPIENT_TYPE_COMPANY_OWNER,
    ACTION_RECIPIENT_TYPE_OTHER,
    ACTION_TYPE_EMAIL,
    ACTION_TYPE_PROVIDER_ACTION,
    ACTION_TYPE_WEB_REQUEST,
    CONDITION_FIELD_TYPE_DATE,
    CONDITION_FIELD_TYPE_OBJECT,
    CONDITION_FIELD_TYPE_REFERENCE,
    NO_VALUE_OPERANDS,
    PAYMENT_REMINDER_OPERANDS
} from '../constants/menu/leftMenuContentConstants';

import {staticMessageFields} from './fieldsAccordionUtils';

import type {
    CompletedMatrixCellStateType, Dispatch,
    FlowActionBlockStateType,
    FlowConditionBlockStateType,
    FlowMatrixStateType,
} from '../constants/flowTyped/flowTypedStates';

const {validateEmail, validateURL} = validators;

/* --------------Common Flow Tree Actions ---------------- */

// Check is condition value can be multiple

// Get current flow-tree state
const getCurrentFlowTree = () => applicationStore.getState().flow;

// Get flow-node & matrix cell position about parent node/cell
export const getPositionAboutParent = (childPosition: ?string): ?string => {
    switch (childPosition) {
        case 'LEFT':
            return 'RIGHT';
        case 'RIGHT':
            return 'LEFT';
        case 'TOP':
            return 'BOTTOM';
        case 'BOTTOM':
            return 'TOP';
        default:
            return null;
    }
};

export const isOperandNonValue = (operand: ?string) => NO_VALUE_OPERANDS.includes(operand);

export const getContentToInsert = (cellToInsert: CompletedMatrixCellStateType, blockType: ?string) => ({
    blockType,
    position: getPositionAboutParent(cellToInsert.parentPosition),
    cellId: cellToInsert.cellId,
    flowNodeId: cellToInsert.cellId
});

/* ---------------- Code for flow Iteration ------------ */

const getBaseBlockData = ({blockType, position, flowNodeId, cellId}) => ({
    blockType,
    position,
    flowNodeId,
    cellId
});

const getFlowNodeConditionBlock = (flowNode: FlowConditionBlockStateType) => {
    const {statement, nextBlockForTrue, nextBlockForFalse} = flowNode;
    return {
        statement,
        nextBlockForTrue,
        nextBlockForFalse
    };
};

const getFlowNodeActionsBlock = (flowNode: FlowActionBlockStateType) => {
    const {type, message, recipient, nextAction, attachEntity, entity, entityAction} = flowNode;
    return {
        type,
        message,
        recipient,
        nextAction,
        attachEntity,
        entity,
        entityAction
    };
};

const getBlockValueData = (flowNode: Object) => {
    if (flowNode.blockType === 'Condition') {
        return getFlowNodeConditionBlock(flowNode);
    }
    if (flowNode.blockType === 'Action') {
        return getFlowNodeActionsBlock(flowNode);
    }
    return {};
};

export const getFlowNodeContent = (flowNode: Object, flowNodeId: number) => {
    let flowContent;
    if (flowNode.flowNodeId === flowNodeId) {
        return {...getBaseBlockData(flowNode), ...getBlockValueData(flowNode)};
    }
    if (flowNode.blockType === 'Action') {
        flowContent = flowNode.nextAction ? getFlowNodeContent(flowNode.nextAction, flowNodeId) : null;
    }
    if (flowContent) {
        return flowContent;
    }
    flowContent = flowNode.nextBlockForTrue ? getFlowNodeContent(flowNode.nextBlockForTrue, flowNodeId) : null;
    if (flowContent) {
        return flowContent;
    }
    return flowNode.nextBlockForFalse ? getFlowNodeContent(flowNode.nextBlockForFalse, flowNodeId) : null;
};

/* ------------ Flow Tree Update Actions ------------- */

// Add to flow first action/condition block from start
export const inputFirstElementInFlow = ({blockType, parentPosition, cellId}: CompletedMatrixCellStateType) => ({
    ...getCurrentFlowTree(),
    block: {
        blockType,
        position: getPositionAboutParent(parentPosition),
        flowNodeId: cellId,
        cellId,
    }
});

export const updateFlowNodeContent = (flowNode: Object, updateContent: Object, flowNodeId: number) => {
    let newFlowNode;
    if (flowNode.flowNodeId === flowNodeId) {
        return {...flowNode, ...updateContent};
    }
    if (flowNode.blockType === 'Action') {
        newFlowNode = flowNode.nextAction ? updateFlowNodeContent(flowNode.nextAction, updateContent, flowNodeId) : null;
    }
    if (newFlowNode) {
        return {...flowNode, nextAction: newFlowNode};
    }
    newFlowNode = flowNode.nextBlockForTrue ? updateFlowNodeContent(flowNode.nextBlockForTrue, updateContent, flowNodeId) : null;
    if (newFlowNode) {
        return {...flowNode, nextBlockForTrue: newFlowNode};
    }
    newFlowNode = flowNode.nextBlockForFalse ? updateFlowNodeContent(flowNode.nextBlockForFalse, updateContent, flowNodeId) : null;
    if (newFlowNode) {
        return {...flowNode, nextBlockForFalse: newFlowNode};
    }
    return null;
};

/* ------------- DELETE FLOW NODE ACTIONS ----------------------*/

const deleteFlowNode = (flowNode, parentId, flowNodeId) => {
    let newFlowNode;
    if (flowNode.flowNodeId === parentId) {
        if (flowNode.nextAction && flowNode.nextAction.flowNodeId === flowNodeId) {
            newFlowNode = {...flowNode, nextAction: null};
        } else if (flowNode.nextBlockForTrue && flowNode.nextBlockForTrue.flowNodeId === flowNodeId) {
            newFlowNode = {...flowNode, nextBlockForTrue: null};
        } else if (flowNode.nextBlockForFalse && flowNode.nextBlockForFalse.flowNodeId === flowNodeId) {
            newFlowNode = {...flowNode, nextBlockForFalse: null};
        }
    }
    if (newFlowNode) {
        return newFlowNode;
    }
    newFlowNode = flowNode.nextAction ? deleteFlowNode(flowNode.nextAction, parentId, flowNodeId) : null;
    if (newFlowNode) {
        return {...flowNode, nextAction: newFlowNode};
    }
    newFlowNode = flowNode.nextBlockForTrue ? deleteFlowNode(flowNode.nextBlockForTrue, parentId, flowNodeId) : null;
    if (newFlowNode) {
        return {...flowNode, nextBlockForTrue: newFlowNode};
    }
    newFlowNode = flowNode.nextBlockForFalse ? deleteFlowNode(flowNode.nextBlockForFalse, parentId, flowNodeId) : null;
    if (newFlowNode) {
        return {...flowNode, nextBlockForFalse: newFlowNode};
    }
    return null;
};

export const deleteFlowNodeFromTree = (parentId: number, flowNodeId: number) => {
    const flowBlock = getCurrentFlowTree().block;
    if (flowBlock && flowBlock.flowNodeId === flowNodeId) {
        return {};
    }
    return deleteFlowNode(flowBlock, parentId, flowNodeId);
};

/* ------------- FLOW ERRORS VALIDATION ACTIONS ----------------------*/

const checkConditionBlock = block => {
    let messagesOfErrors = [];
    const {statement: {fieldsChain, operand, value}, nextBlockForFalse, nextBlockForTrue} = block;
    const {
        FIELDS_PATH_IS_EMPTY, CONDITION_OPERAND_IS_REQUIRED,
        CONDITION_VALUE_IS_REQUIRED, ACTION_IS_MISSING
    } = errorsMessagesConstants;
    if (isEmpty(fieldsChain.fieldsPath)) {
        messagesOfErrors = [...messagesOfErrors, FIELDS_PATH_IS_EMPTY];
    }
    if (!operand) {
        messagesOfErrors = [...messagesOfErrors, CONDITION_OPERAND_IS_REQUIRED];
    }
    if (!head(value) && !isOperandNonValue(operand)) {
        messagesOfErrors = [...messagesOfErrors, CONDITION_VALUE_IS_REQUIRED];
    }
    if (!nextBlockForTrue && !nextBlockForFalse) {
        messagesOfErrors = [...messagesOfErrors, ACTION_IS_MISSING];
    }
    return messagesOfErrors;
};

const checkActionBlock = (block, isSynderAccounting: boolean) => {
    const {type, recipient: {type: recipientType, contact: recipientContact}, message, entity, entityAction} = block;
    let messagesOfErrors = [];
    const {
        TYPE_IS_REQUIRED, RECIPIENT_IS_REQUIRED, VARIABLE_IS_REQUIRED, PROVIDER_ENTITY_ERROR,
        MESSAGE_IS_REQUIRED, EMAIL_IS_INVALID, URL_IS_INVALID, PROVIDER_ENTITY_ACTION_ERROR,
        SYNDER_ENTITY_ERROR, SYNDER_ENTITY_ACTION_ERROR
    } = errorsMessagesConstants;
    if (!type) {
        messagesOfErrors = [...messagesOfErrors, TYPE_IS_REQUIRED];
    }
    if (type === ACTION_TYPE_PROVIDER_ACTION) {
        if (!entity) {
            messagesOfErrors = [...messagesOfErrors, isSynderAccounting ? SYNDER_ENTITY_ERROR : PROVIDER_ENTITY_ERROR];
        }
        if (!entityAction) {
            messagesOfErrors = [...messagesOfErrors, isSynderAccounting ? SYNDER_ENTITY_ACTION_ERROR : PROVIDER_ENTITY_ACTION_ERROR];
        }
    } else {
        if (!recipientType) {
            messagesOfErrors = [...messagesOfErrors, RECIPIENT_IS_REQUIRED];
        }
        if (!recipientContact && recipientType !== ACTION_RECIPIENT_TYPE_COMPANY_OWNER) {
            messagesOfErrors = [...messagesOfErrors, VARIABLE_IS_REQUIRED];
        }
        if (recipientContact && recipientType === ACTION_RECIPIENT_TYPE_OTHER) {
            let error;
            if (type === ACTION_TYPE_EMAIL) {
                error = validateEmail(recipientContact) && EMAIL_IS_INVALID;
            }
            if (type === ACTION_TYPE_WEB_REQUEST) {
                error = validateURL(recipientContact) && URL_IS_INVALID;
            }
            if (error) {
                messagesOfErrors = [...messagesOfErrors, error];
            }
        }
    }
    if (!message.text) {
        messagesOfErrors = [...messagesOfErrors, MESSAGE_IS_REQUIRED];
    }
    return messagesOfErrors;
};

const checkBlockOnError = (block, errors) => {
    const {blockType, nextBlockForFalse, nextBlockForTrue, nextAction, cellId} = block;
    const flowNodeError = {
        cellId,
        messages: []
    };
    let newErrors = errors;
    if (!blockType) {
        flowNodeError.messages = ['No selected block type.'];
    } else {
        if (blockType === 'Condition') {
            flowNodeError.messages = checkConditionBlock(block);

            if (nextBlockForFalse) {
                newErrors = checkBlockOnError(nextBlockForFalse, newErrors);
            }
            if (nextBlockForTrue) {
                newErrors = checkBlockOnError(nextBlockForTrue, newErrors);
            }
        }
        if (blockType === 'Action') {
            flowNodeError.messages = checkActionBlock(block);
            if (nextAction) {
                newErrors = checkBlockOnError(nextAction, newErrors);
            }
        }
    }
    if (!isEmpty(flowNodeError.messages)) {
        newErrors = [...newErrors, flowNodeError];
    }
    return newErrors;
};

export const checkFlowOnError = (customFlow, disableCheckName = false) => {
    const flow = customFlow || getCurrentFlowTree();
    let errors = [];
    const {FLOW_NAME_IS_REQUIRED, FLOW_BLOCK_IS_INVALID} = errorsMessagesConstants;
    if (!flow.name && !disableCheckName) {
        errors.push({cellId: 'name', messages: [FLOW_NAME_IS_REQUIRED]});
    }
    if (isEmpty(flow.block)) {
        errors.push({cellId: 'start', messages: [FLOW_BLOCK_IS_INVALID]});
    } else {
        errors = checkBlockOnError(flow.block, errors);
    }
    return errors;
};

/* ------------- LOAD EXISTED FLOW TO UPDATE ACTIONS ----------------------*/

const getFromParentCell = (parentCell: CompletedMatrixCellStateType, cellPosition: string, newFlowMatrix: FlowMatrixStateType) => {
    let dataToMatrix = null;
    const childId = parentCell[`${cellPosition.toLowerCase()}CellId`];
    newFlowMatrix.matrix.forEach(row => row.forEach(cell => {
        if (childId === cell.cellId) {
            dataToMatrix = cell;
        }
    }));
    return dataToMatrix;
};

const transformStringDateToMomemt = (dateString: string) => (dateString ? moment(dateString) : null);

const getStatementFieldPath = fieldsPath => {
    const {conditionFields} = applicationStore.getState().flowOperations;
    const newPath = [];

    // Inner function for search field by id/name from all field lists for all entities
    const findFieldByParameter = (simpleFieldInPath, simpleFieldInPathKey) => {
        let result = null;
        forOwn(conditionFields, fieldsForEntity => {
            if (!result) {
                result = fieldsForEntity.find(field => field[simpleFieldInPathKey] === simpleFieldInPath[simpleFieldInPathKey]);
            }
        });
        return result;
    };
    // Iterate from API incoming fieldsChain (each element has only id or name)
    fieldsPath.forEach((simpleFieldInPath, index) => {
        let fieldToInsert;
        const simpleFieldInPathKey = simpleFieldInPath.id ? 'id' : 'name';
        // First element always is Id
        if (index === 0) {
            fieldToInsert = findFieldByParameter(simpleFieldInPath, simpleFieldInPathKey);
        } else if (newPath.length === index) {
            // Get previous field in chain where we have already added data
            const {referenceEntities} = newPath[index - 1];
            if (referenceEntities) {
                // At first looking for field in reference entities - normally we should find field here
                referenceEntities.forEach(entity => {
                    if (!fieldToInsert) {
                        fieldToInsert = conditionFields[entity] && conditionFields[entity].find(field => field[simpleFieldInPathKey] === simpleFieldInPath[simpleFieldInPathKey]);
                    }
                });
            }
            if (!fieldToInsert) {
                // Than if there is no result looking for field in all entities
                fieldToInsert = findFieldByParameter(simpleFieldInPath, simpleFieldInPathKey);
            }
        }
        if (fieldToInsert) {
            // If field common (parameter is 'name') we should delete id from found field
            const {id, ...restFieldData} = fieldToInsert;
            newPath.push(simpleFieldInPathKey === 'name' ? restFieldData : fieldToInsert);
        } else {
            newPath.push(simpleFieldInPath);
        }
    });
    return newPath;
};

const generateFlowNode = (parentCell: CompletedMatrixCellStateType, flowNodeFromApi: Object, currentFlowMatrix: Object) => {
    let cellToInsert = getFromParentCell(parentCell, flowNodeFromApi.position, currentFlowMatrix);
    cellToInsert = {
        ...cellToInsert,
        parentPosition: getPositionAboutParent(flowNodeFromApi.position),
        blockType: flowNodeFromApi.blockType
    };
    const {nextAction, nextBlockForTrue, nextBlockForFalse, ...restFlow} = flowNodeFromApi;
    let flowToAdd = {
        // flow-disable-next-line
        flowNodeId: cellToInsert.cellId,
        // flow-disable-next-line
        cellId: cellToInsert.cellId
    };

    const getInputValue = (operand, fieldType, value) => {
        if ([...PAYMENT_REMINDER_OPERANDS, ...NO_VALUE_OPERANDS].includes(operand)) {
            return value;
        }
        return fieldType === CONDITION_FIELD_TYPE_DATE ? [transformStringDateToMomemt(head(value))] : value;
    };

    if (restFlow.blockType === 'Condition') {
        const {blockType, position, statement: {fieldsChain: {fieldsPath}, operand, value}} = restFlow;
        const newPath = getStatementFieldPath(fieldsPath);
        const fieldType = newPath && last(newPath).type;
        flowToAdd = {
            ...flowToAdd,
            blockType,
            position,
            statement: {
                operand,
                fieldsChain: {
                    fieldsPath: newPath
                },
                value: getInputValue(operand, fieldType, value)
            }
        };
    } else {
        flowToAdd = {...flowToAdd, ...restFlow};
    }

    // parent position in cell to set child position
    const parentCellId = parentCell.cellId;
    let newFlowMatrix = {
        matrixSize: currentFlowMatrix.matrixSize,
        matrix: updateMatrixFromRecursion(matrixUpdateFunctionNames.UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING, currentFlowMatrix.matrix, {
            cellToInsert,
            parentCellId
        })
    };
    // flow-disable-next-line
    if (hasCellEmptyNeighbor(cellToInsert)) {
        // flow-disable-next-line
        newFlowMatrix = increaseMatrixSize(newFlowMatrix.matrixSize, newFlowMatrix.matrix);
        cellToInsert = getFromParentCell(parentCell, flowNodeFromApi.position, newFlowMatrix);
    }
    if (nextAction) {
        // flow-disable-next-line
        const {updatedFlowMatrix, flowBlocksTree} = generateFlowNode(cellToInsert, nextAction, newFlowMatrix);
        // flow-disable-next-line
        flowToAdd.nextAction = flowBlocksTree;
        newFlowMatrix = updatedFlowMatrix;
    }
    if (nextBlockForTrue) {
        // flow-disable-next-line
        const {updatedFlowMatrix, flowBlocksTree} = generateFlowNode(cellToInsert, nextBlockForTrue, newFlowMatrix);
        // flow-disable-next-line
        flowToAdd.nextBlockForTrue = flowBlocksTree;
        newFlowMatrix = updatedFlowMatrix;
    }
    if (nextBlockForFalse) {
        // flow-disable-next-line
        const {updatedFlowMatrix, flowBlocksTree} = generateFlowNode(cellToInsert, nextBlockForFalse, newFlowMatrix);
        // flow-disable-next-line
        flowToAdd.nextBlockForFalse = flowBlocksTree;
        newFlowMatrix = updatedFlowMatrix;
    }
    return {flowBlocksTree: flowToAdd, updatedFlowMatrix: newFlowMatrix};
};

export const updateFlowAndMatrix = (flowApi: Object) => {
    /* generating layouts of create flow */
    const {block, ...restFlow} = flowApi;
    const newFlow = restFlow;

    /* Adding start element in flow */
    const center = getCenterElement();
    const newFlowMatrix = {matrixSize: 5, matrix: generateNewMatrix()};
    /* Updating matrix with start and first element */
    const args = {id: center && center.cellId, field: 'blockType', value: 'Start'};
    // flow-disable-next-line
    newFlowMatrix.matrix = updateMatrixFromRecursion(matrixUpdateFunctionNames.UPDATE_CELL_FIELD, newFlowMatrix.matrix, args);

    /* Generate flow tree and matrix according to income flow data */
    // flow-disable-next-line
    const {flowBlocksTree, updatedFlowMatrix} = generateFlowNode(center, block, newFlowMatrix);
    newFlow.block = flowBlocksTree;
    return {newFlow, updatedFlowMatrix, center};
};

/* ------------ Translate field names from action message from/to Api values to/From UI values ----------- */

const replaceWords = (activeEvent, words, isBlockFromExternalFlow) => {
    if (staticMessageFields.includes(words[0])) {
        return words.join('.');
    }
    const nameOrUiName = isBlockFromExternalFlow ? ['uiName', 'name'] : ['name', 'uiName'];
    const {conditionFields} = applicationStore.getState().flowOperations;
    let dataAboutEntity = [];
    if (activeEvent.length <= 1) {
        dataAboutEntity = conditionFields[activeEvent[0].toUpperCase()];
    } else {
        activeEvent.forEach(event => {
            dataAboutEntity.push(conditionFields[event.toUpperCase()]);
            dataAboutEntity = flattenDeep(dataAboutEntity);
        });
    }
    const dataAboutFirstField = find(dataAboutEntity, field => camelCase(field[nameOrUiName[1]]) === words[0]);
    if (dataAboutFirstField && ![CONDITION_FIELD_TYPE_REFERENCE, CONDITION_FIELD_TYPE_OBJECT].includes(dataAboutFirstField.type)) {
        return camelCase(dataAboutFirstField[nameOrUiName[0]]);
    }
    const newArrayOfWords = words;
    if (newArrayOfWords.length > 1) {
        newArrayOfWords.shift();
    }
    if (dataAboutFirstField) {
        return `${camelCase(dataAboutFirstField[nameOrUiName[0]])}.${replaceWords(dataAboutFirstField.referenceEntities, newArrayOfWords, isBlockFromExternalFlow)}`;
    }
    return '';
};

const changeMessage = (text: string, isBlockFromExternalFlow) => {
    const typeOfEvent = applicationStore.getState().events.selectedEvent.entity;
    const isRemoveArrows = text[0] === '{' && text[text.length - 1] === '}';
    let updatedText = text;
    if (isRemoveArrows) {
        updatedText = text.slice(1, -1);
    }
    const splittedByPlaceholdersTexts = updatedText.split('{{');
    if (splittedByPlaceholdersTexts.length > 1) {
        const massifOfParts = splittedByPlaceholdersTexts.map(part => {
            const positionOfArrow = part.indexOf('}}');
            let reservedWords;
            if (positionOfArrow > -1) {
                reservedWords = part.slice(0, positionOfArrow);
                const newReservedWords = reservedWords.replace(/(<([^>]+)>)/ig, '').replace(/\s/g, '');
                const words = replaceWords([typeOfEvent], newReservedWords.split('.'), isBlockFromExternalFlow);
                reservedWords = `{{${words}}}`;
                return reservedWords + part.substring(positionOfArrow + 2);
            }
            return part;
        });
        if (isRemoveArrows) return `{${massifOfParts.join('')}}`;
        return massifOfParts.join('');
    }
    return text;
};

const convertRecipientFromToApi = (recipient: Object, isBlockFromExternalFlow: boolean) => {
    const {type, contact, current} = recipient;
    if (isBlockFromExternalFlow) {
        if (type === ACTION_RECIPIENT_TYPE_COMPANY_OWNER) {
            return {type};
        }
        if (current) {
            return {type, contact: `Current ${capitalize(type)}`};
        }
    }
    if (!isBlockFromExternalFlow && contact === `Current ${capitalize(type)}`) {
        return {type, current: true};
    }
    return recipient;
};

export const checkIsProviderActionNotWrong = text => (dispatch: Dispatch) => {
    let decodedMessageText;
    try {
        decodedMessageText = JSON.parse(text);
    } catch {
        dispatchingIfErrors(dispatch, [{
            cellId: PROVIDER_ACTION_ERROR,
            messages: [PROVIDER_ACTION_ERROR]
        }]);
    }
    return decodedMessageText;
};

export const fieldsTranslater = (block: Object, isBlockFromExternalFlow: boolean) => (dispatch: Dispatch) => {
    const {nextAction, nextBlockForTrue, nextBlockForFalse, blockType, type, recipient, message, statement} = block;
    let newBlock = {...block};
    if (blockType === 'Action' && message) {
        const {text, subject} = message;
        const newMessage = text && changeMessage(text, isBlockFromExternalFlow);

        if (type === ACTION_TYPE_PROVIDER_ACTION) {
            const decodedMessageText = checkIsProviderActionNotWrong(newMessage)(dispatch);
            if (decodedMessageText && decodedMessageText.privateNote === '') {
                delete decodedMessageText.privateNote;
            }
            newBlock = {
                ...newBlock,
                message: {
                    ...message,
                    text: decodedMessageText ? JSON.stringify(decodedMessageText) : ''
                }
            };
        } else if (recipient) {
            const newSubject = subject && changeMessage(subject, isBlockFromExternalFlow);
            newBlock = {
                ...newBlock,
                recipient: convertRecipientFromToApi(recipient, isBlockFromExternalFlow),
                message: {
                    ...message,
                    text: newMessage,
                    subject: newSubject
                }
            };
        }
    }

    if (blockType === 'Condition' && statement && !statement.value) {
        newBlock = {
            ...newBlock,
            statement: {
                ...newBlock.statement,
                value: []
            }
        };
    }
    if (nextAction) {
        newBlock.nextAction = fieldsTranslater(nextAction, isBlockFromExternalFlow)(dispatch);
    }
    if (nextBlockForTrue) {
        newBlock.nextBlockForTrue = fieldsTranslater(nextBlockForTrue, isBlockFromExternalFlow)(dispatch);
    }
    if (nextBlockForFalse) {
        newBlock.nextBlockForFalse = fieldsTranslater(nextBlockForFalse, isBlockFromExternalFlow)(dispatch);
    }
    return newBlock;
};

export const changeConditionBlocksPlaces = (block: Object, flowNodeId: number) => {
    const {nextBlockForTrue, nextBlockForFalse} = block;
    let newBlock = {...block};
    if (block.flowNodeId !== flowNodeId) {
        if (nextBlockForTrue) {
            newBlock = {
                ...newBlock,
                nextBlockForTrue: changeConditionBlocksPlaces(nextBlockForTrue, flowNodeId)
            };
        }
        if (nextBlockForFalse) {
            newBlock = {
                ...newBlock,
                nextBlockForFalse: changeConditionBlocksPlaces(nextBlockForFalse, flowNodeId)
            };
        }
    } else {
        newBlock = {
            ...newBlock,
            nextBlockForTrue: {...nextBlockForFalse},
            nextBlockForFalse: {...nextBlockForTrue}
        };
    }
    return newBlock;
};

export const calculateCountBlocksOfFlow = (obj: Object) => {
    let count = 0;
    // Check if the current object has the blockType property and increment the count if it exists
    if (obj && obj.hasOwnProperty('blockType')) {
        count += 1;
    }

    // Recursively call this function for all object properties that are also objects or arrays
    if(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                count += calculateCountBlocksOfFlow(obj[key]); // Add the count from the nested object
            }
        }
    }

    return count;
};